var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        "is-preview": false,
        isMaskClosable: _vm.isMaskClosable,
        "hide-edit-btn": _vm.hideEditBtn,
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: _vm.isSucDis(_vm.curRow) ? "NOSUC" : "",
        btnLoading: _vm.btnLoading,
        "confirm-btn-disable": _vm.confirmBtnDisable,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
        onConfirm: _vm.onConfirm,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "scroll-view",
          class: {
            "allow-scroll": _vm.isPreview /* , 'no-scroll': isFrameHover */,
          },
          attrs: { slot: "" },
          on: { scroll: _vm.onScroll },
          slot: "default",
        },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.guideName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sequenceNo))]
          ),
          !_vm.propsFilters.typeId
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "分类" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.typeNameString(_vm.curRow.typeId)) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "属性" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.classNameString(_vm.curRow.classId)) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用职位" } },
            _vm._l(_vm.curRow.positions, function (one) {
              return _c("a-tag", { key: one.positionId }, [
                _vm._v(
                  _vm._s(one.positionName + "-") +
                    _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                ),
              ])
            }),
            1
          ),
          _vm.curRow.classId == 3 ||
          _vm.curRow.classId == 10 ||
          _vm.curRow.classId == 11 ||
          _vm.curRow.classId == 12
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "适用工具" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.inspectSimpleFilters(_vm.curRow)) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm.curRow.classId != 5 ? _c("g-title", [_vm._v("正文")]) : _vm._e(),
          _vm.curRow.classId === 1
            ? _c(
                "div",
                [
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "文字方向",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.curRow.textLayout === 1
                            ? "水平"
                            : _vm.curRow.textLayout === 2
                            ? "垂直"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "对齐方式",
                      },
                    },
                    [
                      _vm.curRow.styleItem.textAlign === "left"
                        ? _c("span", [_vm._v(" 左对齐 ")])
                        : _vm.curRow.styleItem.textAlign === "center"
                        ? _c("span", [_vm._v(" 居中对齐 ")])
                        : _vm.curRow.styleItem.textAlign === "right"
                        ? _c("span", [_vm._v(" 右对齐 ")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "行距" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.curRow.styleItem.lineHeight * 100).toFixed(0) +
                            "%"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字高" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.curRow.styleItem.scaleY * 100).toFixed(0) + "%"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字宽" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.curRow.styleItem.scaleX * 100).toFixed(0) + "%"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字号" } },
                    [_vm._v(_vm._s((_vm.curRow.styleItem.fontSize - 1) / 4))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.curRow.classId === 15
            ? _c(
                "div",
                [
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "对齐方式",
                      },
                    },
                    [
                      _vm.curRow.phoneticStyle.textAlign === "left"
                        ? _c("span", [_vm._v(" 左对齐 ")])
                        : _vm.curRow.phoneticStyle.textAlign === "center"
                        ? _c("span", [_vm._v(" 居中对齐 ")])
                        : _vm.curRow.phoneticStyle.textAlign === "right"
                        ? _c("span", [_vm._v(" 右对齐 ")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "行距" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.curRow.phoneticStyle.lineHeight * 100).toFixed(
                            0
                          ) + "%"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字高" } },
                    [
                      _vm._v(
                        _vm._s(
                          (_vm.curRow.phoneticStyle.scaleY * 100).toFixed(0) +
                            "%"
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: { "label-width": _vm.labelWidth, label: "字间距" },
                    },
                    [_vm._v("-" + _vm._s(_vm.curRow.phoneticStyle.scaleX))]
                  ),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字号" } },
                    [
                      _vm._v(
                        _vm._s(_vm.getDisplayFontSize(_vm.curRow.phoneticStyle))
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.curRow.classId === 2
            ? _c("div", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.curRow.guideData) },
                }),
              ])
            : _vm._e(),
          _vm.curRow.classId === 3 ||
          _vm.curRow.classId === 10 ||
          _vm.curRow.classId === 16 ||
          _vm.curRow.classId === 17 ||
          _vm.curRow.classId === 18 ||
          _vm.curRow.classId == 11
            ? _c(
                "div",
                _vm._l(_vm.curRow.blacklist, function (item) {
                  return _c(
                    "div",
                    { key: item.classId, staticClass: "black_word" },
                    [
                      _vm.curRow.classId === 3
                        ? _c("h4", [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.className) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(item.words, function (word, idx) {
                        return _c(
                          "span",
                          { key: idx },
                          [
                            _vm.calcWord(word).length > 20
                              ? _c(
                                  "a-tooltip",
                                  { attrs: { title: _vm.calcWord(word) } },
                                  [
                                    _c("a-tag", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            `${_vm
                                              .calcWord(word)
                                              .slice(0, 20)}...`
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("a-tag", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.calcWord(word)) +
                                      "\n          "
                                  ),
                                ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.curRow.classId === 5
            ? _c(
                "div",
                [
                  _c("g-title", [_vm._v("原稿")]),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "格式" } },
                    [_vm._v(_vm._s(_vm.curRow.size.psdFormat))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "分辨率(DPI)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.psdResolution))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "宽度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.psdWidth))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "最小高度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.psdMinHeight))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "最大高度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.psdMaxHeight))]
                  ),
                  _c("g-title", [_vm._v("完成稿")]),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "格式" } },
                    [_vm._v(_vm._s(_vm.curRow.size.jpgFormat))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "分辨率(DPI)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.jpgResolution))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "宽度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.jpgWidth))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "最小高度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.jpgMinHeight))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "最大高度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.size.jpgMaxHeight))]
                  ),
                  _c("g-title", [_vm._v("切片")]),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "单张最大(M)",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.curRow.size && _vm.curRow.size.sectionSingleSize
                        )
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "总共最大(M)",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.curRow.size && _vm.curRow.size.sectionTotalSize
                        )
                      ),
                    ]
                  ),
                  _c("g-title", [_vm._v("tmb")]),
                  _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "格式" } },
                    [
                      _vm._v(
                        _vm._s(_vm.curRow.size && _vm.curRow.size.tmbFormat)
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "宽度(PX)",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.curRow.size && _vm.curRow.size.tmbWidth)
                      ),
                    ]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "高度(PX)",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.curRow.size && _vm.curRow.size.tmbHeight)
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.curRow.classId === 6
            ? _c(
                "div",
                [
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "章节封面的宽度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.coverImage.thumbnailImageWidth))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "章节封面的高度(PX)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.coverImage.thumbnailImageHeight))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "章节封面的格式",
                      },
                    },
                    [_vm._v(_vm._s(_vm.curRow.coverImage.thumbnailImageFormat))]
                  ),
                  _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "章节封面文件大小(MB)",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.curRow.coverImage.thumbnailImageMaxSize)
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.curRow.classId === 7
            ? _c(
                "div",
                _vm._l(_vm.curRow.blacklist, function (item) {
                  return _c(
                    "div",
                    { key: item.classId, staticClass: "black_word" },
                    [
                      _c("h4", [
                        _vm._v(
                          "\n          " + _vm._s(item.className) + "\n        "
                        ),
                      ]),
                      _vm._l(item.words, function (word, index) {
                        return _c(
                          "a-row",
                          { key: index, attrs: { gutter: 16 } },
                          [
                            _c(
                              "a-col",
                              {
                                staticClass: "py-3",
                                staticStyle: { "white-space": "pre-wrap" },
                                attrs: { span: 6 },
                              },
                              [_vm._v(_vm._s(word.value || ""))]
                            ),
                            _c(
                              "a-col",
                              {
                                staticClass: "py-3",
                                staticStyle: { "white-space": "pre-wrap" },
                                attrs: { span: 6 },
                              },
                              [_vm._v(_vm._s(word.target || ""))]
                            ),
                            _c(
                              "a-col",
                              {
                                staticClass: "py-3",
                                staticStyle: { "white-space": "pre-wrap" },
                                attrs: { span: 12 },
                              },
                              [_vm._v(_vm._s(word.remark || ""))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.curRow.classId === 12
            ? _c(
                "div",
                _vm._l(_vm.curRow.blacklist, function (item) {
                  return _c(
                    "div",
                    { key: item.classId, staticClass: "black_word" },
                    _vm._l(item.words, function (word, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          _vm._l(word.values, function (value, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticStyle: {
                                  "white-space": "pre-line",
                                  "margin-right": "15px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(value || "") +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "white-space": "pre-line",
                              "margin-right": "15px",
                              "text-align": "left",
                              position: "relative",
                              top: "-20px",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(word.remark) +
                                "\n          "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.curRow.classId === 8
            ? _c("div", [
                _vm.curRow.guideData
                  ? _c(
                      "div",
                      _vm._l(_vm.pdfPagesCount, function (i) {
                        return _c("pdf", {
                          key: i,
                          attrs: { src: _vm.pdfDocument, page: i },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.curRow.classId === 9
            ? [
                _c("iframe", {
                  attrs: {
                    src: _vm.curRow.guideData,
                    width: "100%",
                    height: "800px",
                    frameborder: "0",
                  },
                  on: {
                    mouseenter: _vm.moveInIframe,
                    mouseout: _vm.moveOutIframe,
                  },
                }),
              ]
            : _vm._e(),
          _vm.curRow.classId === 4
            ? _c(
                "div",
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 7 } }, [
                        _c("div", { staticClass: "py-3" }, [_vm._v("用途")]),
                      ]),
                      _c("a-col", { attrs: { span: 10 } }, [
                        _c("div", { staticClass: "py-3" }, [_vm._v("字体")]),
                      ]),
                      _c("a-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "py-3" }, [_vm._v("加粗")]),
                      ]),
                      _c("a-col", { attrs: { span: 3 } }, [
                        _c("div", { staticClass: "py-3" }, [_vm._v("颜色")]),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.curRow.fonts, function (item, index) {
                    return _c(
                      "a-row",
                      { key: index, attrs: { gutter: 16 } },
                      [
                        _c(
                          "a-col",
                          {
                            staticClass: "py-3",
                            staticStyle: { "padding-right": "0" },
                            attrs: { span: 7 },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.usage) + "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "a-col",
                          {
                            staticClass: "py-3",
                            staticStyle: { "padding-right": "0" },
                            attrs: { span: 10 },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.fontFamily.join(",")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "a-col",
                          {
                            staticClass: "py-3",
                            staticStyle: { "padding-right": "0" },
                            attrs: { span: 4 },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.isBold ? "是" : "否") +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "a-col",
                          {
                            staticClass: "py-3",
                            staticStyle: { padding: "0" },
                            attrs: { span: 3 },
                          },
                          [
                            _c("a-input", {
                              staticClass: "fontColor",
                              attrs: { type: "color", disabled: "" },
                              model: {
                                value: item.fontColor,
                                callback: function ($$v) {
                                  _vm.$set(item, "fontColor", $$v)
                                },
                                expression: "item.fontColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "scroll-view",
          class: {
            "allow-scroll": _vm.isPreview,
            "no-scroll": _vm.isFrameHover,
          },
          attrs: { slot: "form" },
          slot: "form",
        },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "guideName" } },
                [
                  _vm.formData.id
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.formData.guideName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "guideName", $$v)
                          },
                          expression: "formData.guideName",
                        },
                      })
                    : _c(
                        "a-auto-complete",
                        {
                          attrs: { placeholder: "请输入名称" },
                          on: { change: _vm.guideNameChanged },
                          model: {
                            value: _vm.formData.guideName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "guideName", $$v)
                            },
                            expression: "formData.guideName",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "dataSource" },
                            _vm._l(_vm.guideTemplates, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.id + "" },
                                [_vm._v(_vm._s(item.guideName))]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-full",
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              !_vm.propsFilters.typeId
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "选择分类" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择分类" },
                          on: { change: _vm.typeIdChanged },
                          model: {
                            value: _vm.formData.typeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "typeId", $$v)
                            },
                            expression: "formData.typeId",
                          },
                        },
                        _vm._l(_vm.configFormData.typeIds, function (item) {
                          return _c("a-select-option", { key: item.value }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择属性" } },
                [
                  _vm.propsFilters.typeId == 4
                    ? _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          on: { change: _vm.classIdChanged },
                          model: {
                            value: _vm.formData.classId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "classId", $$v)
                            },
                            expression: "formData.classId",
                          },
                        },
                        _vm._l(
                          _vm.configFormData.classIds.filter((i) => {
                            return (
                              i.value != 1 &&
                              i.value != 2 &&
                              i.value != 4 &&
                              i.value != 5 &&
                              i.value != 6 &&
                              i.value != 8 &&
                              i.value != 9
                            )
                          }),
                          function (item) {
                            return _c("a-select-option", { key: item.value }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.label) +
                                  "\n          "
                              ),
                            ])
                          }
                        ),
                        1
                      )
                    : _vm.propsFilters.typeId == 1
                    ? _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          on: { change: _vm.classIdChanged },
                          model: {
                            value: _vm.formData.classId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "classId", $$v)
                            },
                            expression: "formData.classId",
                          },
                        },
                        _vm._l(
                          _vm.configFormData.classIds.filter((i) => {
                            return (
                              i.value == 1 ||
                              i.value == 5 ||
                              i.value == 6 ||
                              i.value == 4 ||
                              i.value == 13 ||
                              i.value == 15
                            )
                          }),
                          function (item) {
                            return _c("a-select-option", { key: item.value }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.label) +
                                  "\n          "
                              ),
                            ])
                          }
                        ),
                        1
                      )
                    : _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择类型" },
                          on: { change: _vm.classIdChanged },
                          model: {
                            value: _vm.formData.classId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "classId", $$v)
                            },
                            expression: "formData.classId",
                          },
                        },
                        _vm._l(_vm.configFormData.classIds, function (item) {
                          return _c("a-select-option", { key: item.value }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ])
                        }),
                        1
                      ),
                ],
                1
              ),
              _vm.formData.classId == 13
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "选择词汇", prop: "selectedDepart" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            placeholder: "请选择词汇",
                          },
                          on: { change: _vm.vocabularyChange },
                          model: {
                            value: _vm.selectedVocabulary,
                            callback: function ($$v) {
                              _vm.selectedVocabulary = $$v
                            },
                            expression: "selectedVocabulary",
                          },
                        },
                        _vm._l(_vm.vocabularyList, function (item) {
                          return _c("a-select-option", { key: item.guideId }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.guideName) +
                                "\n          "
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择职位", prop: "selectedPositions" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreePositionList,
                      "search-placeholder": "选择职位",
                      placeholder: "请选择适用职位",
                    },
                    on: { change: _vm.changePositionList },
                    model: {
                      value: _vm.selectedPositions,
                      callback: function ($$v) {
                        _vm.selectedPositions = $$v
                      },
                      expression: "selectedPositions",
                    },
                  }),
                ],
                1
              ),
              _vm.formData.classId == 3 ||
              _vm.formData.classId == 10 ||
              _vm.formData.classId == 11 ||
              _vm.formData.classId == 12
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "适用工具", prop: "isSync" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择工具" },
                          on: { change: _vm.inspectSimpleChange },
                          model: {
                            value: _vm.inspectSimpleValue,
                            callback: function ($$v) {
                              _vm.inspectSimpleValue = $$v
                            },
                            expression: "inspectSimpleValue",
                          },
                        },
                        _vm._l(_vm.inspectSimpleList, function (item) {
                          return _c("a-select-option", { key: item.value }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.label) +
                                "\n          "
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.classId != 5 && _vm.formData.classId != 13
                ? _c("g-title", [_vm._v("正文")])
                : _vm._e(),
              _vm.formData.classId === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "文字方向" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择文字方向" },
                              model: {
                                value: _vm.formData.textLayout,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "textLayout", $$v)
                                },
                                expression: "formData.textLayout",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.textLayout,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "对齐方式" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择对齐方式" },
                              model: {
                                value: _vm.formData.styleItem.textAlign,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.styleItem,
                                    "textAlign",
                                    $$v
                                  )
                                },
                                expression: "formData.styleItem.textAlign",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.textAlign,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "行距" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择行距" },
                              model: {
                                value: _vm.formData.styleItem.lineHeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.styleItem,
                                    "lineHeight",
                                    $$v
                                  )
                                },
                                expression: "formData.styleItem.lineHeight",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.lineHeight,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字高" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择字高" },
                              model: {
                                value: _vm.formData.styleItem.scaleY,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.styleItem,
                                    "scaleY",
                                    $$v
                                  )
                                },
                                expression: "formData.styleItem.scaleY",
                              },
                            },
                            _vm._l(_vm.configFormData.scaleY, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.value },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.label) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字宽" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择字宽" },
                              model: {
                                value: _vm.formData.styleItem.scaleX,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.styleItem,
                                    "scaleX",
                                    $$v
                                  )
                                },
                                expression: "formData.styleItem.scaleX",
                              },
                            },
                            _vm._l(_vm.configFormData.scaleX, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.value },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.label) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字号" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "filter-option": _vm.filterOption,
                                "show-search": "",
                                placeholder: "请选择字号",
                              },
                              on: { search: _vm.fontChange },
                              model: {
                                value: _vm.formData.styleItem.fontSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.styleItem,
                                    "fontSize",
                                    $$v
                                  )
                                },
                                expression: "formData.styleItem.fontSize",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.fontSize,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.value,
                                    attrs: { value: item.value },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label + "") +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.classId === 15
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "对齐方式" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择对齐方式" },
                              model: {
                                value: _vm.formData.phoneticStyle.textAlign,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.phoneticStyle,
                                    "textAlign",
                                    $$v
                                  )
                                },
                                expression: "formData.phoneticStyle.textAlign",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.textAlign,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "行距" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择行距" },
                              model: {
                                value: _vm.formData.phoneticStyle.lineHeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.phoneticStyle,
                                    "lineHeight",
                                    $$v
                                  )
                                },
                                expression: "formData.phoneticStyle.lineHeight",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.lineHeight,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字高" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择字高" },
                              model: {
                                value: _vm.formData.phoneticStyle.scaleY,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.phoneticStyle,
                                    "scaleY",
                                    $$v
                                  )
                                },
                                expression: "formData.phoneticStyle.scaleY",
                              },
                            },
                            _vm._l(_vm.configFormData.scaleY, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.value },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.label) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字间距" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择字间距" },
                              model: {
                                value: _vm.formData.phoneticStyle.scaleX,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.phoneticStyle,
                                    "scaleX",
                                    $$v
                                  )
                                },
                                expression: "formData.phoneticStyle.scaleX",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: 100 } }, [
                                _vm._v("\n              -100\n            "),
                              ]),
                              _c("a-select-option", { attrs: { value: 0 } }, [
                                _vm._v("\n              0\n            "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "字号" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择字号" },
                              model: {
                                value: _vm.formData.phoneticStyle.fontSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.phoneticStyle,
                                    "fontSize",
                                    $$v
                                  )
                                },
                                expression: "formData.phoneticStyle.fontSize",
                              },
                            },
                            _vm._l(
                              _vm.configFormData.fontSize,
                              function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.value },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.label) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.formData.classId === 2
                ? _c(
                    "div",
                    [
                      _c("custorm-editor", {
                        model: {
                          value: _vm.formData.guideData,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "guideData", $$v)
                          },
                          expression: "formData.guideData",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.formData.classId === 3 ||
                  _vm.formData.classId === 10 ||
                  _vm.formData.classId === 16 ||
                  _vm.formData.classId === 17 ||
                  _vm.formData.classId === 18 ||
                  _vm.formData.classId === 11 ||
                  _vm.formData.classId === 12
                ? _c(
                    "div",
                    [
                      _vm.formData.classId === 3
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label:
                                  "新增" +
                                  _vm.configFormData.classIds.find(
                                    (item) => item.value == _vm.formData.classId
                                  ).label,
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.handleAddBlackWord },
                                  model: {
                                    value: _vm.formData.typeBackWord,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "typeBackWord",
                                        $$v
                                      )
                                    },
                                    expression: "formData.typeBackWord",
                                  },
                                },
                                _vm._l(_vm.typeBackWordList, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.value },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.title) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.formData.blacklist, function (item, idx) {
                        return _c(
                          "div",
                          { key: item.classId, staticClass: "black_word" },
                          [
                            _vm.formData.classId === 3
                              ? _c(
                                  "div",
                                  { staticClass: "black_header" },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.className) +
                                          "\n            "
                                      ),
                                    ]),
                                    _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确定？",
                                          "ok-text": "是",
                                          "cancel-text": "否",
                                        },
                                        on: {
                                          confirm: () =>
                                            _vm.handleDeleteBlackWordType(item),
                                        },
                                      },
                                      [_c("div", [_vm._v("x")])]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("tag-drag-select", {
                              key: idx,
                              attrs: {
                                "scope-id": idx,
                                classId: _vm.formData.classId,
                                "prop-data-list": item.words,
                              },
                              on: {
                                change: (dataList) =>
                                  _vm.wordsChange(dataList, idx),
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm.formData.classId === 5
                ? _c(
                    "div",
                    [
                      _c("g-title", [_vm._v("原稿")]),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "格式" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { mode: "multiple" },
                              model: {
                                value: _vm.formData.size.psdFormats,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.size, "psdFormats", $$v)
                                },
                                expression: "formData.size.psdFormats",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "PSD" } },
                                [_vm._v("PSD")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "JPG" } },
                                [_vm._v("JPG")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "PNG" } },
                                [_vm._v("PNG")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "分辨率(DPI)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.psdResolution,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.size,
                                  "psdResolution",
                                  $$v
                                )
                              },
                              expression: "formData.size.psdResolution",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "宽度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.psdWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "psdWidth", $$v)
                              },
                              expression: "formData.size.psdWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "最小高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.psdMinHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "psdMinHeight", $$v)
                              },
                              expression: "formData.size.psdMinHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "最大高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.psdMaxHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "psdMaxHeight", $$v)
                              },
                              expression: "formData.size.psdMaxHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("g-title", [_vm._v("完成稿")]),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "格式" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.formData.size.jpgFormat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.size, "jpgFormat", $$v)
                                },
                                expression: "formData.size.jpgFormat",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "JPG" } },
                                [_vm._v("JPG")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "PNG" } },
                                [_vm._v("PNG")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "分辨率(DPI)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.jpgResolution,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.size,
                                  "jpgResolution",
                                  $$v
                                )
                              },
                              expression: "formData.size.jpgResolution",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "宽度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.jpgWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "jpgWidth", $$v)
                              },
                              expression: "formData.size.jpgWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "最小高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.jpgMinHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "jpgMinHeight", $$v)
                              },
                              expression: "formData.size.jpgMinHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "最大高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.jpgMaxHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "jpgMaxHeight", $$v)
                              },
                              expression: "formData.size.jpgMaxHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("g-title", [_vm._v("切片")]),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "单张最大(M)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.sectionSingleSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.size,
                                  "sectionSingleSize",
                                  $$v
                                )
                              },
                              expression: "formData.size.sectionSingleSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "总共最大(M)" } },
                        [
                          _c("a-input-number", {
                            attrs: {
                              min:
                                _vm.formData.size &&
                                _vm.formData.size.sectionSingleSize
                                  ? _vm.formData.size.sectionSingleSize
                                  : 0,
                            },
                            model: {
                              value: _vm.formData.size.sectionTotalSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.size,
                                  "sectionTotalSize",
                                  $$v
                                )
                              },
                              expression: "formData.size.sectionTotalSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("g-title", [_vm._v("tmb")]),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "格式" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { mode: "multiple" },
                              model: {
                                value: _vm.formData.size.tmbFormats,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.size, "tmbFormats", $$v)
                                },
                                expression: "formData.size.tmbFormats",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "JPG" } },
                                [_vm._v("JPG")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "PNG" } },
                                [_vm._v("PNG")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "宽度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.tmbWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "tmbWidth", $$v)
                              },
                              expression: "formData.size.tmbWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value: _vm.formData.size.tmbHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.size, "tmbHeight", $$v)
                              },
                              expression: "formData.size.tmbHeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.formData.classId == 6
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "章节封面的宽度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value:
                                _vm.formData.coverImage.thumbnailImageWidth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.coverImage,
                                  "thumbnailImageWidth",
                                  $$v
                                )
                              },
                              expression:
                                "formData.coverImage.thumbnailImageWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "章节封面的高度(PX)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value:
                                _vm.formData.coverImage.thumbnailImageHeight,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.coverImage,
                                  "thumbnailImageHeight",
                                  $$v
                                )
                              },
                              expression:
                                "formData.coverImage.thumbnailImageHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "章节封面的格式" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value:
                                  _vm.formData.coverImage.thumbnailImageFormat,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.coverImage,
                                    "thumbnailImageFormat",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.coverImage.thumbnailImageFormat",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "PNG" } },
                                [_vm._v("PNG")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "JPG" } },
                                [_vm._v("JPG")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "章节封面文件大小(MB)" } },
                        [
                          _c("a-input-number", {
                            model: {
                              value:
                                _vm.formData.coverImage.thumbnailImageMaxSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.coverImage,
                                  "thumbnailImageMaxSize",
                                  $$v
                                )
                              },
                              expression:
                                "formData.coverImage.thumbnailImageMaxSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.formData.classId == 7
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "添加类型" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择专用词汇类型" },
                              on: { change: _vm.handleAddGlossaryType },
                              model: {
                                value: _vm.formData.typeGlossaryList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "typeGlossaryList",
                                    $$v
                                  )
                                },
                                expression: "formData.typeGlossaryList",
                              },
                            },
                            _vm._l(_vm.typeGlossaryList, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.value },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.title) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.formData.blacklist, function (item, idx) {
                        return _c(
                          "div",
                          { key: item.classId, staticClass: "black_word" },
                          [
                            _c(
                              "div",
                              { staticClass: "black_header" },
                              [
                                _c("h4", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.className) +
                                      "\n            "
                                  ),
                                ]),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "确定？",
                                      "ok-text": "Yes",
                                      "cancel-text": "No",
                                    },
                                    on: {
                                      confirm: () =>
                                        _vm.handleDeleteGlossaryType(item),
                                    },
                                  },
                                  [_c("div", [_vm._v("x")])]
                                ),
                              ],
                              1
                            ),
                            _c("tag-drag-select", {
                              key: idx,
                              attrs: {
                                "scope-id": idx,
                                classId: _vm.formData.classId,
                                "prop-data-list": item.words,
                              },
                              on: {
                                change: (dataList) =>
                                  _vm.wordsChange(dataList, idx),
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm.formData.classId == 8
                ? _c(
                    "div",
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            headers: _vm.headers,
                            accept: ".pdf",
                            name: "file",
                            "show-upload-list": false,
                            action: "/jeecg-boot/sys/common/upload",
                          },
                          on: { change: _vm.handleChangeUpload },
                        },
                        [
                          _c(
                            "a-button",
                            [
                              _c("a-icon", { attrs: { type: "upload" } }),
                              _vm._v(" 上传文件 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.formData.guideData
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "16px" } },
                            _vm._l(_vm.pdfPagesCount, function (i) {
                              return _c("pdf", {
                                key: i,
                                attrs: { src: _vm.formData.guideData, page: i },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.formData.classId === 9
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "设置外链" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入外链地址" },
                          model: {
                            value: _vm.formData.guideData,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "guideData", $$v)
                            },
                            expression: "formData.guideData",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("iframe", {
                      attrs: {
                        src: _vm.formData.guideData,
                        width: "100%",
                        height: "800px",
                        frameborder: "0",
                      },
                      on: {
                        mouseenter: _vm.moveInIframe,
                        mouseout: _vm.moveOutIframe,
                      },
                    }),
                  ]
                : _vm.formData.classId !== 1
                ? _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c("a-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "py-3" }, [
                              _vm._v("用途"),
                            ]),
                          ]),
                          _c("a-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "py-3" }, [
                              _vm._v("字体"),
                            ]),
                          ]),
                          _c("a-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "py-3" }, [
                              _vm._v("加粗"),
                            ]),
                          ]),
                          _c("a-col", { attrs: { span: 3 } }, [
                            _c("div", { staticClass: "py-3" }, [
                              _vm._v("颜色"),
                            ]),
                          ]),
                          _c("a-col", { attrs: { span: 4 } }, [
                            _c("div", { staticClass: "py-3" }, [
                              _vm._v("操作"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(_vm.formData.fonts, function (item, index) {
                        return _c(
                          "a-row",
                          {
                            key: index,
                            staticClass: "mb-6",
                            attrs: { gutter: 16 },
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      placeholder: "选择用途",
                                      dropdownClassName:
                                        "font-select-guide-pro",
                                    },
                                    model: {
                                      value: item.usage,
                                      callback: function ($$v) {
                                        _vm.$set(item, "usage", $$v)
                                      },
                                      expression: "item.usage",
                                    },
                                  },
                                  _vm._l(_vm.textStyleTypeList, function (itm) {
                                    return _c(
                                      "a-select-option",
                                      { key: itm.value },
                                      [_vm._v(_vm._s(itm.label))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      dropdownClassName:
                                        "font-select-guide-pro",
                                      "filter-option": _vm.filterOption,
                                      placeholder: "选择字体",
                                      mode: "multiple",
                                      value: item.fontFamily,
                                    },
                                    model: {
                                      value: item.fontFamily,
                                      callback: function ($$v) {
                                        _vm.$set(item, "fontFamily", $$v)
                                      },
                                      expression: "item.fontFamily",
                                    },
                                  },
                                  _vm._l(
                                    _vm.configFormData.defaultFontFamily,
                                    function (itm) {
                                      return _c(
                                        "a-select-option",
                                        { key: itm.displayName },
                                        [_vm._v(_vm._s(itm.displayName))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 3 } },
                              [
                                _c("a-switch", {
                                  attrs: {
                                    defaultChecked: item.isBold ? true : false,
                                  },
                                  on: {
                                    change: (e) => _vm.switchChange(e, item),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 3 } },
                              [
                                _c("a-input", {
                                  staticClass: "fontColor",
                                  attrs: { type: "color" },
                                  model: {
                                    value: item.fontColor,
                                    callback: function ($$v) {
                                      _vm.$set(item, "fontColor", $$v)
                                    },
                                    expression: "item.fontColor",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 4 } },
                              [
                                index > 0
                                  ? _c("a-button", {
                                      staticClass: "mr-3",
                                      attrs: { shape: "circle", icon: "minus" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeField(index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("a-button", {
                                  attrs: { shape: "circle", icon: "plus" },
                                  on: { click: _vm.addField },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }